.chevron {
  transition: transform 0.2s ease-in-out;
}

.chevronButton {
  cursor: pointer;
  margin: auto;
  margin-top: 8px;
  outline: none;
  border: none;
  background-color: transparent;
  width: 16px;

  @media desktop-s {
    margin: 0;
  }
}
